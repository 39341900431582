<template>
    <div>
        <div v-if="isLoading">
            <b-loading v-model="isLoading" :is-full-page="false" />
        </div>
        <div v-else class="d-flex flex-column align-items-start" style="gap: 2rem">
            <b-button type="is-light" size="is-small" icon-left="backward" @click="backToSites">
                {{ $t('partenaire-site.back') }}
            </b-button>
            <OrdersTable :orders="orders" :income="income" @loadAsyncData="getData" />
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'
    import OrdersTable from '../../../components/Sites/OrdersTable'
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'Orders',
        title: 'global.orders',
        components: {
            OrdersTable,
            Toaster
        },
        data: function () {
            return {
                orders: {},
                income: 0
            }
        },
        computed: {
            ...mapState('orders', {
                isLoading: 'isLoading'
            })
        },
        methods: {
            ...mapActions('orders', {
                getOrdersBySite: 'getOrdersBySite'
            }),
            getData(params = null) {
                this.getOrdersBySite({
                    siteId: this.$route.query.id,
                    params
                }).then(({ orders, income }) => {
                    this.orders = orders
                    this.income = Number.parseFloat(income) || 0
                })
            },

            backToSites() {
                window.location.href = '/partner/sites'
            }
        }
    }
</script>
